<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
      <h2 class="brand-text text-primary ml-1"></h2
    ></a>
    <!-- /Brand logo-->
    <!-- Left Text-->
    <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
      <div
        class="w-100 d-lg-flex align-items-center justify-content-center px-5"
      >
        <img
          class="img-fluid"
          [src]="
            coreConfig.layout.skin === 'dark'
              ? 'assets/images/pages/forgot-password-v2-dark.svg'
              : 'assets/images/pages/forgot-password-v2.svg'
          "
          alt="Forgot password V2"
        />
      </div>
    </div>
    <!-- /Left Text-->
    <!-- Forgot password-->
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <h2 class="card-title font-weight-bold mb-1">
          Réinitialiser votre Mot de passe🔒
        </h2>
        <form
          class="auth-forgot-password-form mt-2"
          [formGroup]="resetPasswordForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label class="form-label" for="forgot-password-email"
              >Mot de passe actuel</label
            >
            <input
              class="form-control"
              type="password"
              formControlName="actualPassword"
              autofocus=""
              tabindex="1"
              placeholder="············"
              [ngClass]="{
                'is-invalid':
                  (submitted || f.actualPassword.touched) &&
                  (f.actualPassword.errors?.required ||
                    f.actualPassword.errors?.minLength ||
                    f.actualPassword.errors?.requireUppercase ||
                    f.actualPassword.errors?.requireLowercase ||
                    f.actualPassword.errors?.requireNumber ||
                    f.actualPassword.errors?.requireSpecialChar)
              }"
            />
            <div style="display: flex; flex-direction: column">
              <span
                *ngIf="
                  (submitted || f.actualPassword.touched) &&
                  f.actualPassword.errors?.required
                "
                class="text-danger"
                >Mot de passe requis</span
              >
              <span
                *ngIf="
                  (submitted || f.actualPassword.touched) &&
                  f.actualPassword.errors?.minLength
                "
                class="text-danger"
                >Le mot de passe doit comporter au moins 8 caractères</span
              >
              <span
                *ngIf="
                  (submitted || f.actualPassword.touched) &&
                  f.actualPassword.errors?.requireUppercase
                "
                class="text-danger"
                >Le mot de passe doit contenir au moins une lettre
                majuscule</span
              >
              <span
                *ngIf="
                  (submitted || f.actualPassword.touched) &&
                  f.actualPassword.errors?.requireLowercase
                "
                class="text-danger"
                >Le mot de passe doit contenir au moins une lettre
                minuscule</span
              >
              <span
                *ngIf="
                  (submitted || f.actualPassword.touched) &&
                  f.actualPassword.errors?.requireNumber
                "
                class="text-danger"
                >Le mot de passe doit contenir au moins un chiffre</span
              >
              <span
                *ngIf="
                  (submitted || f.actualPassword.touched) &&
                  f.actualPassword.errors?.requireSpecialChar
                "
                class="text-danger"
                >Le mot de passe doit contenir au moins un caractère
                spécial</span
              >
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="forgot-password-email"
              >Nouveau mot de passe</label
            >
            <input
              class="form-control"
              type="password"
              formControlName="password"
              autofocus=""
              tabindex="1"
              placeholder="············"
              [ngClass]="{
                'is-invalid':
                  (submitted || f.password.touched) &&
                  (f.password.errors?.required ||
                    f.password.errors?.minLength ||
                    f.password.errors?.requireUppercase ||
                    f.password.errors?.requireLowercase ||
                    f.password.errors?.requireNumber ||
                    f.password.errors?.requireSpecialChar)
              }"
            />
            <div style="display: flex; flex-direction: column">
              <span
                *ngIf="
                  (submitted || f.password.touched) &&
                  f.password.errors?.required
                "
                class="text-danger"
                >Mot de passe requis</span
              >
              <span
                *ngIf="
                  (submitted || f.password.touched) &&
                  f.password.errors?.minLength
                "
                class="text-danger"
                >Le mot de passe doit comporter au moins 8 caractères</span
              >
              <span
                *ngIf="
                  (submitted || f.password.touched) &&
                  f.password.errors?.requireUppercase
                "
                class="text-danger"
                >Le mot de passe doit contenir au moins une lettre
                majuscule</span
              >
              <span
                *ngIf="
                  (submitted || f.password.touched) &&
                  f.password.errors?.requireLowercase
                "
                class="text-danger"
                >Le mot de passe doit contenir au moins une lettre
                minuscule</span
              >
              <span
                *ngIf="
                  (submitted || f.password.touched) &&
                  f.password.errors?.requireNumber
                "
                class="text-danger"
                >Le mot de passe doit contenir au moins un chiffre</span
              >
              <span
                *ngIf="
                  (submitted || f.password.touched) &&
                  f.password.errors?.requireSpecialChar
                "
                class="text-danger"
                >Le mot de passe doit contenir au moins un caractère
                spécial</span
              >
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="forgot-password-email"
              >Confirmation du mot de passe</label
            >
            <input
              class="form-control"
              type="password"
              formControlName="confirmPassword"
              autofocus=""
              tabindex="1"
              placeholder="············"
              [ngClass]="{
                'is-invalid':
                  (submitted || f.confirmPassword.touched) &&
                  (f.confirmPassword.errors?.required ||
                    f.confirmPassword.errors?.minLength ||
                    f.confirmPassword.errors?.requireUppercase ||
                    f.confirmPassword.errors?.requireLowercase ||
                    f.confirmPassword.errors?.requireNumber ||
                    f.confirmPassword.errors?.requireSpecialChar ||
                    f.password.value != f.confirmPassword.value)
              }"
            />
            <div style="display: flex; flex-direction: column">
              <span
                *ngIf="
                  (submitted || f.confirmPassword.touched) &&
                  f.confirmPassword.errors?.required
                "
                class="text-danger"
                >Mot de passe requis
              </span>
              <span
                *ngIf="
                  (submitted || f.confirmPassword.touched) &&
                  f.password.value != f.confirmPassword.value
                "
                class="text-danger"
                >Mot de passe n'est pas conforme
              </span>
            </div>
          </div>
          <button class="btn btn-primary btn-block" tabindex="2" rippleEffect>
            Réinitialiser
          </button>
        </form>
      </div>
    </div>
    <!-- /Forgot password-->
  </div>
</div>
