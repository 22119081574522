import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthenticationService } from "app/auth/service";
import { menu } from "app/menu/menu";
import { NotificationService } from "app/services/toast.service";
@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _notificationService: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem("token");
    const role = JSON.parse(localStorage.getItem("userData"))?.role;

    if (token) {
      const userRoles = [role]; // Replace with your authentication service logic

      const url = removeFirstSlash(state.url.split("?")[0]); // Removing query parameters from the URL
      const isAuthorizedForURL = isAuthorized(url, userRoles, menu);

      if (isAuthorizedForURL) {
        return true;
      } else {
        this._notificationService.showError(
          "vous n'êtes pas autorisé à y accéder.",
          "Désolé"
        );
        this._router.navigate(["/home"]);
        return false;
      }
    } else {
      this._router.navigate(["/login"]);
      return false;
    }
  }
}

function isAuthorized(url: string, userRoles: string[], menu): boolean {
  for (const menuItem of menu) {
    if (menuItem.url === url) {
      // Check if any of the user's roles match the roles required for this URL
      return menuItem.role?.some((role) => userRoles.includes(role));
    } else if (menuItem.children) {
      // Check if the URL is in any of the children of this menu item
      for (const childItem of menuItem.children) {
        if (childItem.url === url) {
          // Check if any of the user's roles match the roles required for this URL
          return childItem.role.some((role) => userRoles.includes(role));
        }
      }
    }
  }
  // URL not found in the menu configuration
  return true;
}

function removeFirstSlash(inputString) {
  if (inputString.startsWith("/")) {
    return inputString.substring(1);
  }
  return inputString;
}
