import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthenticationService } from "app/auth/service";
import { NotificationService } from "app/services/toast.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private notificationService: NotificationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (
          [401, 403].indexOf(err.status) !== -1 &&
          !request.url.split("/").includes("login-admin")
        ) {
          this._authenticationService.logout();
          this.notificationService.showError(
            "vous n'êtes pas autorisé à y accéder.",
            "Désolé"
          );
          return;
        }
        let msg = "Erreur du serveur";
        // throwError
        if (err.status === 404) {
          msg = "Elément non trouvé";
        }
        if (err.status === 400) {
          if (err.error?.issues?.length) {
            msg = err.error.issues.map((issue) => issue.message).join("\n");
          } else {
            msg = "Veuillez vérifier vos données";
          }
        }
        return throwError(msg);
      })
    );
  }
}
