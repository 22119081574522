import { AbstractControl, ValidatorFn } from "@angular/forms";

export function PasswordValidator(
    minLength: number,
    requireUppercase: boolean,
    requireLowercase: boolean,
    requireNumber: boolean,
    requireSpecialChar: boolean
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.value;
  
      // Check minimum length
      if (password?.length < minLength) {
        return { minLength: true };
      }
  
      // Check uppercase requirement
      if (requireUppercase && !/[A-Z]/.test(password)) {
        return { requireUppercase: true };
      }
  
      // Check lowercase requirement
      if (requireLowercase && !/[a-z]/.test(password)) {
        return { requireLowercase: true };
      }
  
      // Check number requirement
      if (requireNumber && !/[0-9]/.test(password)) {
        return { requireNumber: true };
      }
  
      // Check special character requirement
      if (
        requireSpecialChar &&
        !/[!@#$%^&*()\-=_+[\]{}|\\:;"'<>,.?/~`]/.test(password)
      ) {
        return { requireSpecialChar: true };
      }
  
      // All requirements passed
      return null;
    };
  }