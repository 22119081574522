import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "home",
    role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
  },
  {
    id: "Category",
    title: "Category",
    translate: "MENU.CATEGORY.COLLAPSIBLE",
    type: "collapsible",
    icon: "grid",
    role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.CATEGORY.VIEW",
        type: "item",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
        icon: "circle",
        url: "category/view-category",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.CATEGORY.ADD",
        type: "item",
        icon: "circle",
        url: "category/add-category",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
      },
    ],
  },
  {
    id: "definition",
    title: "definition",
    translate: "MENU.DEFINITION.COLLAPSIBLE",
    type: "collapsible",
    icon: "key",
    role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.DEFINITION.VIEW",
        type: "item",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
        icon: "circle",
        url: "definitions/view-definitions",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.DEFINITION.ADD",
        type: "item",
        icon: "circle",
        url: "definitions/add-definition",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
      },
    ],
  },
  {
    id: "Rule",
    title: "Rule",
    translate: "MENU.RULE.COLLAPSIBLE",
    type: "collapsible",
    icon: "menu",
    role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.RULE.VIEW",
        type: "item",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
        icon: "circle",
        url: "rule/view-rule",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.RULE.ADD",
        type: "item",
        icon: "circle",
        url: "rule/add-rule",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
      },
    ],
  },
  {
    id: "Question",
    title: "Questions",
    translate: "MENU.QUESTION.COLLAPSIBLE",
    type: "collapsible",
    icon: "help-circle",
    role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.QUESTION.VIEW",
        type: "item",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
        icon: "circle",
        url: "question/view-question",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.QUESTION.ADD",
        type: "item",
        icon: "circle",
        url: "question/add-question",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
      },
    ],
  },
  {
    id: "levelTest",
    title: "User",
    translate: "MENU.LEVELTEST.COLLAPSIBLE",
    type: "collapsible",
    icon: "check-square",
    role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.LEVELTEST.VIEW",
        type: "item",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
        icon: "circle",
        url: "level-test/view-level-test",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.LEVELTEST.ADD",
        type: "item",
        icon: "circle",
        url: "level-test/add-level-test",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
      },
    ],
  },
  {
    id: "Anecdote",
    title: "Anecdote",
    translate: "MENU.ANECDOTE.COLLAPSIBLE",
    type: "collapsible",
    icon: "smile",
    role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.ANECDOTE.VIEW",
        type: "item",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
        icon: "circle",
        url: "anecdote/view-anecdote",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.ANECDOTE.ADD",
        type: "item",
        icon: "circle",
        url: "anecdote/add-anecdote",
        role: ["SUPER_ADMIN", "ADMIN", "EDITOR"],
      },
    ],
  },
  {
    id: "admin",
    title: "admin",
    translate: "MENU.ADMIN.COLLAPSIBLE",
    type: "collapsible",
    icon: "user",
    role: ["SUPER_ADMIN"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.ADMIN.VIEW",
        type: "item",
        role: ["SUPER_ADMIN"],
        icon: "circle",
        url: "admin/view-admins",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.ADMIN.ADD",
        type: "item",
        icon: "circle",
        url: "admin/add-admin",
        role: ["SUPER_ADMIN"],
      },
    ],
  },
  {
    id: "coach",
    title: "coach",
    translate: "MENU.COACH.COLLAPSIBLE",
    type: "collapsible",
    icon: "user",
    role: ["SUPER_ADMIN"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.COACH.VIEW",
        type: "item",
        role: ["SUPER_ADMIN"],
        icon: "circle",
        url: "coach/view-coachs",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.COACH.ADD",
        type: "item",
        icon: "circle",
        url: "coach/add-coach",
        role: ["SUPER_ADMIN"],
      },
    ],
  },
  {
    id: "user",
    title: "user",
    translate: "MENU.USER.COLLAPSIBLE",
    type: "collapsible",
    icon: "users",
    role: ["SUPER_ADMIN"],
    children: [
      {
        id: "View",
        title: "View",
        translate: "MENU.USER.VIEW",
        type: "item",
        role: ["SUPER_ADMIN","ADMIN"],
        icon: "circle",
        url: "user/view-user",
      },
      {
        id: "Add",
        title: "Add",
        translate: "MENU.USER.ADD",
        type: "item",
        icon: "circle",
        url: "user/add-user",
        role: ["SUPER_ADMIN","ADMIN"],
      },
    ],
  },
];
